import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Tag } from 'rsuite'
import LegacyTagIcon from '@rsuite/icons/legacy/Tag'

import { getHighlight } from '../lib/resources/attribute'
import { ProductAttribute } from '../utils/types/Product'
import { ProductProperty } from '../utils/types/Resource'

import styles from '../styles/Tag.module.less'

interface HighlightTagProps {
  label?: string
  attributes: ProductAttribute[]
  productProperty?: ProductProperty[]
  type?: string | string[]
  className?: string
}

const HighlightTag: FC<HighlightTagProps> = (props) => {
  const { label, attributes, productProperty, type = 'feature', className = '' } = props
  const { t } = useTranslation('feature')
  const highlightLabel = label ?? getHighlight(attributes, type, productProperty)

  if (!highlightLabel) {
    return null
  }
  return (
    <Tag className={`${styles.tag} ${styles.highlight} ${styles.bordered} ${className} ellipsis`} data-testid="highlight-tag">
      <LegacyTagIcon className="icon-size-double" />
      {t(`${highlightLabel}`)}
    </Tag>
  )
}

export default HighlightTag
