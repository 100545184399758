import React, { FC } from 'react'

const RightCarouselIcon: FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" transform="rotate(-180 20 20)" fill="white" stroke="#E5E5EA" />
    <path d="M17.8683 22.8661L20.7334 20L17.8683 17.1339C17.3801 16.6457 17.3801 15.8543 17.8683 15.3661C18.3564 14.878 19.1479 14.878 19.6361 15.3661L23.3861 19.1161C23.5738 19.3039 23.6893 19.5365 23.7327 19.7795L23.75 19.9262L23.75 20.0738C23.7327 20.3687 23.6114 20.6586 23.3861 20.8839L19.636 24.6339C19.1479 25.122 18.3564 25.122 17.8683 24.6339C17.3801 24.1457 17.3801 23.3543 17.8683 22.8661Z" fill="#8E8E93" />
  </svg>
)

export default RightCarouselIcon
