import { FC, useEffect, useRef, useState } from 'react'
import { Button, Stack } from 'rsuite'
import { useRouter } from 'next/router'
import throttle from 'lodash/throttle'

import LeftCarouselIcon from '../Icons/LeftCarouselIcon'
import RightCarouselIcon from '../Icons/RightCarouselIcon'
import { getRefDimension } from '../../utils/util'
import useForceUpdate from '../../services/useForceUpdate'

import styles from '../../styles/Carousel.module.less'

const SCROLL_OFFSET = 270
const GRADIENT_OFFSET = 20 // Top + bottom padding
const FIRST_LEFT_SCROLL_OFFSET = 225
const FIRST_RIGHT_SCROLL_OFFSET = 215

interface CarouselProps {
  scrollOffset?: number
  firstLeftScrollOffset?: number
  firstRightScrollOffset?: number
  reRenderDependency?: boolean | number | string // For force render right and left button
  scrollToEnd?: boolean
  scrollToStart?: boolean
  hideArrowsFade?: boolean
  hideArrows?: boolean
  gradientOffset?: number
  horizontalScrollIconOffset?: number
}

const Carousel: FC<CarouselProps> = (props) => {
  const {
    children,
    scrollOffset = SCROLL_OFFSET,
    firstLeftScrollOffset = FIRST_LEFT_SCROLL_OFFSET,
    firstRightScrollOffset = FIRST_RIGHT_SCROLL_OFFSET,
    gradientOffset = GRADIENT_OFFSET,
    reRenderDependency,
    scrollToEnd,
    scrollToStart,
    hideArrowsFade,
    hideArrows = false,
    horizontalScrollIconOffset = 0,
  } = props

  const [renderRightIcon, setRenderRightIcon] = useState(true)
  const [renderLeftIcon, setRenderLeftIcon] = useState(false)
  const [scrollRefHeight, setScrollRefHeight] = useState(0)

  const scrollRef = useRef<HTMLDivElement>(null)
  const { query } = useRouter()
  const forceUpdate = useForceUpdate()

  const refHeight = getRefDimension('height', scrollRef)

  const shouldRenderCarouselLeftIcon = () => (scrollRef.current
    ? scrollRef?.current?.scrollLeft > 0 - horizontalScrollIconOffset
    : false)

  const shouldRenderRightCarouselIcon = () => !!scrollRef?.current
    && Math.ceil(scrollRef?.current?.offsetWidth
      + scrollRef?.current?.scrollLeft)
    < scrollRef?.current?.scrollWidth + horizontalScrollIconOffset

  const onArrowiconClick = (direction: 'right' | 'left') => () => {
    if (!scrollRef.current) return

    const shouldRenderRightIcon = scrollRef?.current?.offsetWidth
      + scrollRef?.current?.scrollLeft
      < scrollRef?.current?.scrollWidth

    const getScrollAmount = () => {
      if (direction === 'left') {
        return shouldRenderRightIcon ? scrollOffset : firstLeftScrollOffset
      }
      // Direction right:
      return scrollRef?.current && scrollRef.current.scrollLeft < 1
        ? firstRightScrollOffset
        : scrollOffset
    }

    const scrollPosition = scrollRef.current.scrollLeft
    const scrollAmount = direction === 'right' ? getScrollAmount() : -(getScrollAmount())

    scrollRef.current.scrollTo({
      top: 0,
      left: scrollPosition + scrollAmount,
    })
  }

  const onRightArrowIconClick = onArrowiconClick('right')
  const onLeftArrowIconClick = onArrowiconClick('left')

  const handleHideArrowIcons = throttle(() => {
    if (!scrollRef.current) return

    setRenderRightIcon(shouldRenderRightCarouselIcon())
    setRenderLeftIcon(shouldRenderCarouselLeftIcon())
  }, 50)

  useEffect(() => {
    forceUpdate() // To re-render for scrollref
  }, [])

  useEffect(() => {
    if (query?.filters) {
      // scroll to start on category change
      scrollRef?.current?.scrollTo(0, 0)
      handleHideArrowIcons() // Run once on mount
    }
  }, [JSON.stringify(query?.filters?.slice(1))])

  useEffect(() => {
    if (scrollToStart) {
      scrollRef.current?.scrollTo(0, 0)
    }
  }, [scrollToStart])

  useEffect(() => {
    if (scrollToEnd) {
      scrollRef.current?.scrollTo(scrollRef.current?.scrollWidth, 0)
    }
  }, [scrollToEnd])

  // Force render right and left button
  useEffect(() => {
    if (reRenderDependency !== undefined) {
      setRenderRightIcon(shouldRenderRightCarouselIcon())
    }
  }, [reRenderDependency])

  // Updating scroll ref height after initial render
  useEffect(() => {
    setScrollRefHeight(refHeight - gradientOffset)
  }, [refHeight, reRenderDependency])

  return (
    <div className={styles.carousel}>
      <Stack alignItems="flex-start">
        <div className="relative">
          {/* eslint-disable-next-line
          jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            // Get gradient height dynamically
            style={{ height: scrollRefHeight }}
            className={`${hideArrowsFade ? '' : styles['gradient-element-left']} ${!renderLeftIcon ? styles.hidden : ''}`}
            onClick={onLeftArrowIconClick}
          />
        </div>
        <Stack>
          {!hideArrows && (
            <Button
              className={`${styles['left-icon']} ${styles['scroll-icon']} ${!renderLeftIcon ? styles.hidden : ''}`}
              appearance="subtle"
              onClick={onLeftArrowIconClick}
            >
              <LeftCarouselIcon />
            </Button>
          )}
          <div
            ref={scrollRef}
            onScroll={handleHideArrowIcons}
            className={`${styles['scroll-container']} scroll-container-ref`}
          >
            {children}
          </div>
          {!hideArrows && (
            <Button
              className={`${styles['right-icon']} ${styles['scroll-icon']} ${!renderRightIcon ? styles.hidden : ''}`}
              appearance="subtle"
              onClick={onRightArrowIconClick}
            >
              <RightCarouselIcon />
            </Button>
          )}

        </Stack>
        <div className="relative">
          {/* eslint-disable-next-line
          jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            // Get gradient height dynamically
            style={{ height: scrollRefHeight }}
            className={`${hideArrowsFade ? '' : styles['gradient-element-right']} ${!renderRightIcon ? styles.hidden : ''}`}
            onClick={onRightArrowIconClick}
          />
        </div>
      </Stack>
    </div>
  )
}
export default Carousel
