import { FC, memo } from 'react'
import { Button, Panel, Tag } from 'rsuite'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import dynamic from 'next/dynamic'

import useUrls from '../../../services/useUrls'
import AnchorLink from '../../AnchorLink'
import { Currency, QueryParam, ScreenSize } from '../../../utils/constants'
import { deliveryHelper, formatToRows, getEllipsesText } from '../../../utils/util'
import { formatPriceNumber } from '../../../utils/math'
import { Product } from '../../../utils/types/Product'
import { getLargePreview, imageLoader } from '../../../utils/images'
import {
  getPricePerPieceUnit,
  getProductSalesCount,
  getReferencePrice,
  getSalesUnit,
  getSmallestBarcodeItemsMoq,
} from '../../../lib/salesUnit'
import { getCustomPrices, getLowestCustomPrice, getProductPrice, getProductPriceObject } from '../../../lib/resources/price'
import { getPathName, getProductMoq, isFreeDelivery, isNewProduct, isProductV2 } from '../../../lib/product'
import { getProductName, getProductImageAltText } from '../../../lib/text'
import { getSupplierDeliveryDetails } from '../../../lib/service'
import { getCountryAndLocaleStrings } from '../../../utils/locales'
import HighlightTag from '../../HighlightTag'
import useIsOnMobile from '../../../services/useIsOnMobile'
import BulkDiscountTag from '../../BulkDiscountTag'
import useGetAnalyticsLocation from '../../../services/analytics/useGetAnalyticsLocation'
import useRFQModal from '../../Modals/hooks/useRFQModal'
import SaveProductButton from '../../MyProducts/SaveProductButton'
import { useAuth } from '../../../services/useAuth'
import NameTagContract from '../../CustomPricing/NameTagContract'
import ProductEmptyImage from '../../SingleProduct/ProductEmptyImage'
import { getVariantType } from '../../../lib/variant'
import { isSuperUser } from '../../../lib/supplier'

import styles from '../../../styles/ProductCard.module.less'
import tagStyles from '../../../styles/Tag.module.less'

const SizeIndicator = dynamic(() => import('./SizeIndicator'))
const ColorIndicator = dynamic(() => import('./ColorIndicator'))

interface ProductCardProps {
  product: Product
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const {
    product,
  } = props

  const { query, locale: countryAndLocale, pathname } = useRouter()
  const { t } = useTranslation('products')
  const { urlT } = useUrls()
  const analyticsLocation = useGetAnalyticsLocation('product_card')
  const openRFQModal = useRFQModal({ analyticsLocation, product })
  const isOnMobile = useIsOnMobile(ScreenSize.md)
  const { user } = useAuth()

  const salesUnit = getSalesUnit(product)
  const customPrices = user ? getCustomPrices(product) : []
  const lowestCustomTierDiscount = getLowestCustomPrice(customPrices)

  const { country } = getCountryAndLocaleStrings(countryAndLocale)
  const productUrl = urlT(getPathName(product, t))
  const { minimumOrderValue: mov } = getSupplierDeliveryDetails(product.supplier[0], country)

  // TODO: doesn't consider language now, should later filter based on
  //  text.languageid and current saved client language

  const productPrice = getProductPriceObject(product)
  const freeShipping = isFreeDelivery(product, country)
  const { deliveryTime } = getSupplierDeliveryDetails(product.supplier[0], country)
  const currency = productPrice?.['price.currencyid'] ? Currency[productPrice?.['price.currencyid']] : ''
  const selectedSupplier = query[QueryParam.supplierCode] as string
  const isRFQProduct = !getProductPrice(product)
  const imageAltText = getProductImageAltText(product)

  // Feature flags - manage from Growthbook UI
  const discountTagFeatureFlag = useFeatureIsOn('discount-tag')
  const saveProductFeatureFlag = useFeatureIsOn('product-card-heart-icon')
  const bulkDiscountTagFlag = discountTagFeatureFlag
    ? <BulkDiscountTag isOnProductCard product={product} country={country} />
    : null

  const colorIndicatorFeatureFlag = useFeatureIsOn('product-catalog-color-indicator')
  const sizeIndicatorFeatureFlag = useFeatureIsOn('product-catalog-size-indicator')

  const renderIndicators = () => {
    const isColorVariant = getVariantType(product.product) === 'color'

    if (colorIndicatorFeatureFlag && isColorVariant) {
      return <ColorIndicator product={product} />
    }

    if (sizeIndicatorFeatureFlag) {
      return <SizeIndicator product={product} />
    }

    return null
  }

  const productCardLinkTarget = pathname.startsWith('/cart') ? '_blank' : undefined

  return (
    <div
      className={`${styles['product-card']} ${styles['product-card-animation']}`}
      data-testid="product-card"
      data-cy="product-card"
    >
      <Panel bodyFill className={styles['desktop-panel-border']}>
        <AnchorLink
          data-cy="product-card-link"
          target={productCardLinkTarget}
          href={productUrl}
          className={styles['anchor-link']}
        >
          <div className={styles['top-container']}>
            <div className={`align-middle ${styles['indicator-container']}`}>
              {renderIndicators()}
            </div>
            <div className={`${styles.figure} ${styles.relative}`}>
              <div className={styles['image-container-height']}>
                <HighlightTag
                  label={product['product.highlight']}
                  attributes={product.attribute}
                  productProperty={product['product/property']}
                  className={`${tagStyles['top-tag']} ${tagStyles['z-index-1']}`}
                  type={isProductV2(product) ? ['label', 'feature-type', 'standards-test'] : 'feature'}
                />
                {product.media?.length ? (
                  <Image
                    loader={imageLoader}
                    src={getLargePreview(product?.media?.[0])}
                    alt={imageAltText}
                    layout="fill"
                  />
                ) : (
                  <ProductEmptyImage
                    height={250}
                    width={250}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`${styles['bottom-container']} ${styles.relative}`}>
            <div>
              {isNewProduct(product) && (
                <Tag color="primary" className={`${tagStyles.tag} ${tagStyles['left-tag']} tag-primary`}>
                  {t('New')}
                </Tag>
              )}
            </div>
            <Tag className={`${tagStyles.tag} ${tagStyles['right-tag']} ${tagStyles.bordered}`}>
              {freeShipping && !isRFQProduct && t('Free')}
              &nbsp;
              {isRFQProduct ? t('Quote only') : deliveryHelper(deliveryTime, t, freeShipping)}
            </Tag>
            <div className={styles.body}>
              <div className={`h6 ${styles.name} margin-top-spacer`} data-testid="product-card-title">
                {formatToRows(getEllipsesText(`${getProductName(product)}${getProductSalesCount(product, t)}`, 47), 34)}
              </div>
              <p className={styles['product-discounts']}>
                {isRFQProduct ? (
                  <b className={styles['margin-right-spacer-half']}>
                    {t('Price available on request')}
                  </b>
                )
                  : (
                    <b className={styles['margin-right-spacer-half']}>
                      {formatPriceNumber(getReferencePrice(product, lowestCustomTierDiscount?.['price.value']))}
                      {' '}
                      {currency}
                      {' '}
                      <small>
                        {'/ '}
                        {t(getPricePerPieceUnit(product), { count: 1 })}
                      </small>
                    </b>
                  )}
                {' '}
                {bulkDiscountTagFlag}
                {customPrices.length > 0 && (
                  <NameTagContract
                    name={user?.company ?? ''}
                    tooltipText={t(
                      '{{buyer}} contract price for bulk purchasing starting from {{count}} {{salesUnit}}',
                      { buyer: user?.company, count: lowestCustomTierDiscount?.['price.quantity'], salesUnit },
                    )}
                    customStyles={styles['contract-price']}
                  />
                )}
              </p>
              <p className={`${styles['product-minimum']} ${styles.ellipses}`}>
                {t('Minimum')}
                {' '}
                {getSmallestBarcodeItemsMoq(product, t, getProductMoq(product))}
              </p>
            </div>
            {!selectedSupplier && (
              <div className={styles['supplier-container']}>
                <b>{product.supplier?.[0]?.['supplier.label']}</b>
                {!!mov && <p>{`${t('Orders from')} ${formatPriceNumber(mov)} ${currency}`}</p>}
              </div>
            )}
          </div>
        </AnchorLink>
        <div className={styles['buttons-container']}>
          {saveProductFeatureFlag ? (
            <SaveProductButton
              analyticsLocation={analyticsLocation}
              product={product}
              className={styles['save-product-button']}
              appearance="ghost"
              iconClassName={styles['heart-icon']}
            />
          ) : !isOnMobile && (
            <Button
              appearance="ghost"
              onClick={(e) => {
                e.preventDefault()
                openRFQModal()
              }}
            >
              {t('Request a quote')}
            </Button>
          )}
          <AnchorLink
            className={styles['view-product-anchor-link']}
            href={productUrl}
            target={productCardLinkTarget}
          >
            <Button
              appearance="primary"
              className={`
                ${styles['view-product-button-right-radius']}
                ${isSuperUser(user) ? styles['view-product-button-left-radius'] : ''}
              `}
            >
              {t('View product')}
            </Button>
          </AnchorLink>
        </div>
      </Panel>
    </div>
  )
}

export default memo(ProductCard)
