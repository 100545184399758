import React, { FC, useEffect, Dispatch, SetStateAction } from 'react'
import { useRouter } from 'next/router'
import { Button, Message, toaster } from 'rsuite'
import { TypeAttributes } from 'rsuite/esm/@types/common'

import { useAuth } from '../../services/useAuth'
import useSavedProduct from '../hooks/useSavedProduct'
import { AnalyticsLocation } from '../../utils/types/analytics'
import { Product } from '../../utils/types/Product'
import HeartIcon from '../Icons/SavedProducts/HeartIcon'
import { ALERT_DURATION } from '../../utils/constants'
import useAdminCustomers from '../../services/useAdminCustomers'
import { isSuperUser } from '../../lib/supplier'

import styles from '../../styles/SaveProductButton.module.less'

interface SaveProductButtonProps {
  product: Product
  analyticsLocation?: AnalyticsLocation
  className?: string
  appearance?: TypeAttributes.Appearance
  setIsRefreshing?: Dispatch<SetStateAction<boolean>>
  onSaveProduct?: () => void
  onUnsaveProduct?: () => void
  iconClassName?: string
}

const SaveProductButton: FC<SaveProductButtonProps> = (props) => {
  const {
    product,
    analyticsLocation,
    className = '',
    appearance = 'ghost',
    setIsRefreshing,
    onSaveProduct = () => {},
    onUnsaveProduct = () => {},
    iconClassName,
  } = props

  const { user } = useAuth()
  const { pathname } = useRouter()

  const {
    selectedCustomer,
  } = useAdminCustomers()

  const {
    handleSaveProductToggle,
    isProductSaved,
    errorMessage,
    isRefreshing,
  } = useSavedProduct(product, selectedCustomer)

  const isMyProductsPage = pathname === '/my-products'

  useEffect(() => {
    if (errorMessage) {
      toaster.push(
        <Message type="error" showIcon closable duration={ALERT_DURATION}>
          {errorMessage}
        </Message>,
      )
    }
  }, [errorMessage])

  // Expose isRefreshing to parent component
  useEffect(() => {
    if (setIsRefreshing) {
      setIsRefreshing(isRefreshing)
    }
  }, [isRefreshing])

  const handleOnClick = () => {
    if (isProductSaved) {
      onUnsaveProduct()
    }

    if (!isMyProductsPage) {
      handleSaveProductToggle(analyticsLocation)
    }

    if (!isProductSaved) {
      onSaveProduct()
    }
  }

  const displayProductAsSaved = isProductSaved && (!user?.superuser || isMyProductsPage)

  if (isSuperUser(user)) {
    return null
  }

  return (
    <>
      <Button
        className={`${styles['save-product-button']} ${className}`}
        appearance={appearance}
        onClick={handleOnClick}
      >
        <HeartIcon
          variant={displayProductAsSaved ? 'primary-dark-filled' : 'dark'}
          className={iconClassName}
        />
      </Button>
    </>
  )
}

export default SaveProductButton
